.home{
    align-items: center;
    /* height: 100%; */
    width:100%;
    overflow: scroll;
    height: 100vh;
}

.downloads{
    align-items: center;
    height: 100%;
    width:100%;
    overflow: scroll;
    height: 100vh;
}

.header{
    display: flex;
    background-color: #7d2999;
    width:100%;
    height: 40px;
    color: #ffffff;
    justify-content: flex-end;
}

.header-button{
    height : 100%;
    background: transparent;
    color: #ffffff;
    /* font-weight: bold; */
    font-size: 16px;
    border: none;
    padding: 5px 10px;
}

.header-button:hover{
    color: white;
    background-color: #564068;
    cursor: pointer;
}

.container{
    background-color: #464646b0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-full{
    background-color: #46464680;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@keyframes float {
    0% {
        /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
        transform: translatey(0px);
    }
    50% {
        /* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
        transform: translatey(-8px);
    }
    100% {
        /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
        transform: translatey(0px);
    }
}

.logo{
    display: block;
    width: 80%;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    animation: float 4s ease-in-out infinite;
    object-fit: cover;
    image-rendering: auto;
    filter: drop-shadow(5px 10px 15px);
}

.logo-big{
    display: block;
    width: 80%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    animation: float 4s ease-in-out infinite;
    object-fit: cover;
    image-rendering: auto;
    filter: drop-shadow(5px 10px 15px);
}

.contents{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.contents-full{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    max-width: 95vw;
    margin-top: 25px;
}

.images{
    width: 40%;
    height: 50%;
    display: flex;
    flex-direction: column;
}

.preview{
    height: 100%;
    width: 100%;
    margin-bottom: 2%;
    object-fit: contain;

    image-rendering: smooth;
}

.images-full{
    width: auto;
    height: 210px;
    overflow: auto;
    display: inline-flex;
    flex-flow: nowrap !important;
    flex-direction: row;
    image-rendering: smooth;
}

.preview-full{
    height: 200px;
    width: auto;
    object-fit: contain;
    image-rendering: smooth;
}

.text{
    margin-left: 2%;
    margin-right: 2%;
    /* text-align: center; */
    color: #ffffff;
    width: 60%;
    line-height: 1.5;
    font-size: 18px;
}

.text-links{
    margin-left: 2%;
    margin-right: 2%;
    /* text-align: center; */
    color: #ffffff;
    line-height: 1.5;
    font-size: 18px;
    text-shadow: 0px 0px 8px #000000;
    text-align:center
}

.text-full{
    margin-top: 4%;
    margin-left: 2%;
    margin-right: 2%;
    /* text-align: center; */
    color: #ffffff;
    width: 96%;
    line-height: 1.5;
    font-size: 18px;
}

.text-downloads{
    margin-left: 2%;
    margin-right: 2%;
    /* text-align: center; */
    color: #ffffff;
    /* width: 60%; */
    line-height: 1.5;
    font-size: 18px;
}

a {
    color: #f9d;
}

.modal .a{
    color: #fff;
}

.important-button{
    background: #af00ff;
    color: #fff;
    font-size: 20px;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
    margin: 10px;
    box-shadow: 0px 2px 10px 5px rgb(0,0,0,0.2);
}

.paypal{
    background: #9d25ff;
    color: #e5e5e5;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
    box-shadow: 0px 2px 10px 5px rgb(0,0,0,0.2);
    margin-top: 10px;

    a {
        color: #e5e5e5;
    }
}

.important-button:hover{
    cursor: pointer;
}

.frameCont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
}

.header-mobile{
    display: flex;
    background-color: #7d2999;
    width:100%;
    height: 50px;
    color: #e5e5e5;
    justify-content: flex-start;
}

.header-mobile-open{
    display: flex;
    background-color: #7d2999;
    width:100%;
    /* height: 200px; */
    color: #e5e5e5;
    flex-direction: column;
    justify-content: flex-start;
}

.menu{
    height : 42px;
    width: 42px;
    background: transparent;
    color: #e5e5e5;
    /* font-weight: bold; */
    font-size: 16px;
    border: none;
    margin: 4px;

    background-size: cover;
    /* filter: invert(0.8); */
}

.menu:hover{
    color: white;
    background-color: #564068;
    cursor: pointer;
    border-radius: 4px;
}

.header-button-mobile{
    height : 100%;
    width: 100%;
    background: transparent;
    color: #e5e5e5;
    /* font-weight: bold; */
    font-size: 16px;
    border: none;
    padding: 5px 10px;
    text-align: left;
}

.header-button-mobile:hover{
    color: white;
    background-color: #564068;
    cursor: pointer;
}

.emulator{
    width: 480px;
    height: 320px;

    border: 4px solid #a038b5 ;

    align-self: center;
}

.version-title{
    margin-left: 20px;
}

.link{
    margin-left: 25px;
}

.modal{
    background: #dadada;
    border-radius: 5px;

    padding: 20px;
    a {
        color: #f0a;
    }
}

.modal > .head{
    font-size: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid gray;
}

.page-mask{
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.downloads-box {
    background: #50505070;
    padding: 25px;
    margin: 25px;
    border: 5px;
}

.download {
    position: absolute;
    top: 95%;
    left: 50%;
    display:flex;
    flex-direction: row;
    transform:translateX(-50%);
}

.icon {
    width: 24px;
    height: 24px;
    margin-left: 5px;

    object-fit: contain;
    opacity: 0.8;
}

.icon-downloads {
    transform:translateY(25%);
    width: 24px;
    height: 24px;
    margin-right: 5px;

    object-fit: contain;
    opacity: 0.8;
}
